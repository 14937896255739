import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mr-3 font-normal" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "text-lg font-semibold" }
const _hoisted_6 = { class: "text-sm italic color-blue-grey" }
const _hoisted_7 = {
  class: "pl-3",
  style: {"border-left":"1px solid #ECEFF1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_DxExport = _resolveComponent("DxExport")
  const _component_DxPaging = _resolveComponent("DxPaging")
  const _component_DxPager = _resolveComponent("DxPager")
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")
  const _component_DxColumn = _resolveComponent("DxColumn")
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")
  const _component_DxGrouping = _resolveComponent("DxGrouping")
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")
  const _component_Card = _resolveComponent("Card")
  const _component_ListCopyEditDel = _resolveComponent("ListCopyEditDel")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_AddPermissionDialog = _resolveComponent("AddPermissionDialog")
  const _component_DialogConfirm = _resolveComponent("DialogConfirm")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("h6", _hoisted_3, _toDisplayString(`Permission ${_ctx.groupUserName}`), 1),
            _createVNode(_component_Button, {
              label: "Tambah Permission",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addPermission())),
              icon: "pi pi-plus",
              class: "font-semibold p-button-sm btn-primary"
            })
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DxDataGrid, {
            ref: "refTableDeliveryItem",
            id: "delivery-item-table-id",
            class: "delivery-item-table",
            "column-auto-width": true,
            "allow-column-reordering": false,
            "show-column-lines": false,
            "show-row-lines": true,
            "show-borders": true,
            "row-alternation-enabled": true,
            "allow-column-resizing": false,
            "word-wrap-enabled": true,
            "data-source": _ctx.userPermissionList,
            "key-expr": "Id",
            "no-data-text": _ctx.noDataText,
            onExporting: _ctx.exportGrids
          }, {
            descriptionTemplate: _withCtx(({ data }) => [
              _createVNode("div", null, [
                _createVNode("div", _hoisted_5, _toDisplayString(data.data.Description), 1),
                _createVNode("div", _hoisted_6, _toDisplayString(`( ${data.data.Name} )`), 1)
              ])
            ]),
            actionTemplate: _withCtx(({ data }) => [
              _createVNode("div", _hoisted_7, [
                _createVNode("i", {
                  class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
                  "aria:haspopup": "true",
                  "aria-controls": "overlay_panel",
                  onClick: ($event: any) => (_ctx.showDropDown($event, data.data))
                }, null, 8, ["onClick"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DxExport, {
                enabled: false,
                "allow-export-selected-data": false
              }),
              _createVNode(_component_DxPaging, {
                "page-size": 20,
                enabled: true
              }),
              _createVNode(_component_DxPager, {
                visible: true,
                "allowed-page-sizes": _ctx.pageSizes,
                "display-mode": _ctx.displayMode,
                "show-page-size-selector": _ctx.showPageSizeSelector,
                "show-info": _ctx.showInfo,
                "show-navigation-buttons": _ctx.showNavButtons
              }, null, 8, ["allowed-page-sizes", "display-mode", "show-page-size-selector", "show-info", "show-navigation-buttons"]),
              _createVNode(_component_DxFilterRow, { visible: _ctx.showFilterRow }, null, 8, ["visible"]),
              _createVNode(_component_DxHeaderFilter, { visible: _ctx.showHeaderFilter }, null, 8, ["visible"]),
              _createVNode(_component_DxColumnChooser, {
                enabled: false,
                mode: "select",
                title: "Pilih Kolom",
                "allow-search": true
              }),
              _createVNode(_component_DxColumn, {
                "data-field": "groupName",
                caption: "Group",
                visible: true,
                width: 130
              }),
              _createVNode(_component_DxColumn, {
                caption: "Permission Yang Diijinkan",
                "data-field": "Description",
                visible: true,
                "min-width": 130,
                "cell-template": "descriptionTemplate",
                alignment: "left",
                "allow-grouping": false
              }),
              _createVNode(_component_DxColumn, {
                caption: "Action",
                visible: true,
                width: 100,
                "cell-template": "actionTemplate",
                alignment: "left"
              }),
              _createVNode(_component_DxGroupPanel, {
                visible: true,
                "empty-panel-text": "Drag kolom ke sini untuk mengelompokkan menurut kolom tersebut"
              }),
              _createVNode(_component_DxGrouping, {
                "auto-expand-all": false,
                "context-menu-enabled": true,
                "expand-mode": "rowClick"
              }),
              _createVNode(_component_DxSearchPanel, { visible: true })
            ]),
            _: 1
          }, 8, ["data-source", "no-data-text", "onExporting"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_OverlayPanel, { ref: "op" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListCopyEditDel, {
          del: true,
          onDel: _ctx.deleteData
        }, null, 8, ["onDel"])
      ]),
      _: 1
    }, 512),
    (_ctx.isShowAddPermissionDialog)
      ? (_openBlock(), _createBlock(_component_AddPermissionDialog, {
          key: 0,
          show: _ctx.isShowAddPermissionDialog,
          currentGroupId: _ctx.currentId()?.toString(),
          groupName: _ctx.groupUserName,
          onHideDialog: _ctx.hideAddPermissionDialog,
          onOnSubmit: _ctx.onSubmitPermission
        }, null, 8, ["show", "currentGroupId", "groupName", "onHideDialog", "onOnSubmit"]))
      : _createCommentVNode("", true),
    _createVNode(_component_DialogConfirm, { ref: "refDialogConfirm" }, null, 512)
  ]))
}