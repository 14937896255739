
import {
  computed, defineComponent, getCurrentInstance, onMounted, reactive, ref
} from 'vue'
import { groupUserUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { downloadExcel } from '@/plugins/report/downloadExcel'

export default defineComponent({
  name: 'TrayekForm',
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    permissionName: {
      type: String,
      default: () => ''
    },
    groupName: {
      type: String,
      default: () => ''
    },
    currentGroupId: {
      type: String,
      default: ''
    },
  },
  components: {
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxPager
  },
  emits: ['hideDialog', 'onSubmit'],
  setup(props, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const { permissionName, groupName, currentGroupId } = propertiesProps
    const store = useStore()
    const showModal = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const permissionList = ref([]) as any
    const userPermissionList = ref([]) as any
    const selectedList = ref([])
    const inputSearch = ref('')
    const isLoading = ref(true)

    const noDataText = ref('Tidak Ada Data')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const displayMode = ref('full')
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const showFilterRow = ref(true)
    const showHeaderFilter = ref(true)
    const autoExpandAll = ref(true)

    const closeDialog = () => {
      showModal.value = false
    }

    const submitData = () => {
      const arr = selectedList.value.filter((x: any) => !userPermissionList.value.includes(x))
      const permissions = arr.map((data: any) => data.Id)
      emit('onSubmit', currentGroupId, permissions)
    }

    const getAllPermissionList = async (search: any = '') => {
      store.dispatch('showLoading')
      const response = await groupUserUseCase.getAllPermission()
      if (!response.error) {
        permissionList.value = response.result.map((data: any) => {
          const textArr = data.Description.split(' - ')
          const obj = {
            ...data,
            groupName: textArr[0]
          }
          return obj
        })
      }
      console.log('list', permissionList.value)
      store.dispatch('hideLoading')
    }

    const getAllUserPermissionList = async () => {
      store.dispatch('showLoading')
      const response = await groupUserUseCase.getUserPermissions(currentGroupId)
      if (!response.error) {
        userPermissionList.value = response.result.map((data: any) => {
          const textArr = data.Description.split(' - ')
          const obj = {
            ...data,
            groupName: textArr[0]
          }
          return obj
        })
        selectedList.value = userPermissionList.value
      }
      isLoading.value = false
      store.dispatch('hideLoading')
    }

    const checkPermissionExist = (id: string) => {
      const res = userPermissionList.value.find((val: any) => val.Id === id)
      return res
    }

    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(userPermissionList.value, 'Group User Permission')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }

    onMounted(() => {
      getAllPermissionList()
      getAllUserPermissionList()
      // getAllData()
    })

    return {
      showModal,
      closeDialog,
      submitData,
      inputSearch,
      getAllPermissionList,
      selectedList,
      permissionList,
      isLoading,
      checkPermissionExist,
      noDataText,
      pageSizes,
      displayMode,
      showPageSizeSelector,
      showInfo,
      showNavButtons,
      showFilterRow,
      showHeaderFilter,
      autoExpandAll
    }
  }
})
