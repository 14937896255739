
import {
  computed, defineComponent, onMounted, reactive
} from 'vue'

export default defineComponent({
  name: 'TrayekForm',
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    permissionName: {
      type: String,
      default: () => ''
    },
    groupName: {
      type: String,
      default: () => ''
    },
  },
  emits: ['hideDialog', 'onSubmit'],
  setup(props, { emit }: any) {
    const propertiesProps = reactive(props)
    const { permissionName, groupName } = propertiesProps
    const showModal = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })

    const closeDialog = () => {
      showModal.value = false
    }

    const submitData = () => {
      emit('onSubmit')
    }

    onMounted(() => {
      console.log('asadasd')
    })

    return {
      showModal,
      closeDialog,
      submitData
    }
  }
})
